.terminal-4-frame-9 {
  display: flex;
  height: 44px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-top: 1px solid #aec1ff;
}

.terminal-text-16 {
  align-self: stretch;
  color: #5a81fa;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.terminal-text-17 {
  display: flex;
  height: 14px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: #5a81fa;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
