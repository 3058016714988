.model-2-frame-20 {
  display: flex;
  width: 183px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.model-2-frame-21 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.model-2-frame-22 {
  display: flex;
  height: 44px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  flex: 1 0 0;
  border-left: 1px solid rgba(255, 255, 255, 0.55);
}

.model-2-frame-23 {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0px 10px 10px 0px;
  background: #e9eaee;
}

.model-2-frame-24 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  flex: 1 0 0;
}

.model-text-7 {
  display: flex;
  height: 15px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #5a81fa;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.model-2-frame-25 {
  display: flex;
  height: 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.model-2-frame-26 {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px 0px 0px 10px;
  background: #e9eaee;
}
