.custom-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 5px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  text-align: right;
}

.dropdown-header-2 {
  display: flex;
  width: 52px;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #333;
}

.dropdown-header-2-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 5px;
}

.dropdown-header-title-2 {
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.dropdown-header-title {
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 5px;
  color: #333333;
}

.dropdown-header-text {
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  margin: 5px;
  color: #373734;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 150px;
  overflow: auto;
  z-index: 1000;
  box-sizing: border-box;
  text-align: right;
}

.dropdown-item {
  margin: 0;
  padding: 20px;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  font-size: 16px;
  margin: 5px;
  color: #333333;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item.selected {
  background-color: #d0d0d0;
}
