.info-window-frame {
  display: flex;
  width: 134px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.info-window-title,
.info-window-text {
  color: #2b3361;
  text-align: center;
}

.info-window-title {
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.info-window-text {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
