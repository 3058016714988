.div-main-2-2 {
  background-color: #f0f1f5;
  overflow: auto;
  padding: 56px 34px;
}

.terminal-frame {
  height: 134px;
  width: 685px;
  display: flex;
  padding: 30px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 15px;
  background: #2b308b;
}

.terminal-text-1 {
  color: #aec2ff;
  font-family: "Almoni DL AAA";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.terminal-text-2 {
  color: #aec2ff;
  font-family: Montserrat;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.terminal-text-3 {
  color: #aec1ff;
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.terminal-frame-inner {
  display: flex;
  width: 107px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  border-radius: 10px;
  box-sizing: border-box;
  background: rgba(31, 31, 31, 0.5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.terminal-frame-inner-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.terminal-text-4 {
  width: 66px;
  color: #46cdb3;
  text-align: center !important;
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.terminal-text-5 {
  color: #46cdb3;
  text-align: center !important;
  font-family: Montserrat;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.terminal-2-frame {
  display: flex;
  height: 118px;
  padding: 30px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  border-radius: 15px;
  background: #5a81fa;
  box-sizing: border-box;
}

.terminal-text-6 {
  color: #fff;
  font-family: Montserrat;
  font-size: 33px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
}

.terminal-text-7 {
  color: #fff;
  font-family: Montserrat;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.terminal-3-frame {
  display: flex;
  padding: 8px 20px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  background: #7999ff;
  box-sizing: border-box;
}

.terminal-text-8 {
  color: #fff;
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.terminal-text-9 {
  color: #fff;
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
}

.terminal-3-frame-2 {
  display: flex;
  width: 86px;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.terminal-3-frame-3 {
  display: flex;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.terminal-3-frame-4 {
  display: flex;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.terminal-3-frame-5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.terminal-text-10 {
  color: #fff;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.terminal-text-11 {
  color: #fff;
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.terminal-4-frame {
  display: flex;
  width: 745px;
  padding: 20px 30px 30px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.terminal-4-frame-2 {
  display: flex;
  height: 27px;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.terminal-text-12 {
  color: #46cdb3;
  font-family: "Almoni DL AAA";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
}

.terminal-4-frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}

.terminal-4-frame-4 {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.terminal-4-frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  flex: 1 0 0;
}

.terminal-4-frame-6 {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  border-radius: 15px;
  background: #fff;
}

.terminal-4-frame-7 {
  display: flex;
  height: 52px;
  width: 52px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 28px;
  align-self: stretch;
  border-radius: 15px;
  background: #19209c;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.terminal-text-13 {
  color: #fff;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.terminal-4-frame-8 {
  display: flex;
  width: 70px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  flex: 1 0 auto;
}

.terminal-text-14 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #3e417a;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.terminal-text-15 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #3e417a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.terminal-4-frame-10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

.terminal-4-frame-11 {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.terminal-4-frame-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

.terminal-4-frame-13 {
  display: flex;
  height: 53px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-top: 1px solid #aec1ff;
}

.terminal-5-frame {
  display: flex;
  width: 1005px;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.terminal-5-frame-2 {
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;
}

.terminal-5-frame-3 {
  display: flex;
  height: 136px;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 15px;
  background: #2b3361;
  box-sizing: border-box;
}

.terminal-5-frame-4 {
  display: flex;
  width: 115px;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
}

.terminal-5-frame-5 {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 8px;
}

.terminal-text-18 {
  display: flex;
  width: 90px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #46cdb3;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.terminal-5-frame-6 {
  display: flex;
  width: 115px;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
}

.terminal-5-frame-7 {
  display: flex;
  padding: 35px 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 auto;
  align-self: stretch;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
}

.terminal-5-frame-8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

.terminal-5-frame-9 {
  display: flex;
  padding: 10px 20px 20px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 15px;
  background: #2b3361;
}

.terminal-5-frame-10 {
  display: flex;
  padding: 0px 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
}

.terminal-text-19 {
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: #3e417a;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.terminal-6-frame {
  width: 1005px;
  display: flex;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;
}

.terminal-6-frame-2 {
  display: flex;
  padding: 20px 30px 30px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
}

.terminal-6-frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}

.terminal-6-frame-4 {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.terminal-6-frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

.terminal-6-frame-6 {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.terminal-7-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.terminal-7-frame-2 {
  display: flex;
  height: 220px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.terminal-7-frame-3 {
  display: flex;
  height: 43px;
  width: 43px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  border-radius: 15px;
  background: #2b308b;
  box-sizing: border-box;
}

.terminal-7-frame-4 {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.terminal-7-frame-5 {
  display: flex;
  height: 43px;
  width: 43px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  border-radius: 15px;
  background: #5a81fa;
  box-sizing: border-box;
}

.terminal-7-frame-6 {
  display: flex;
  align-items: flex-start;
  margin-top: auto;
  gap: 6px;
}

.terminal-7-frame-7 {
  display: flex;
  width: 44px;
  height: 55px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  box-sizing: border-box;
}

.terminal-8-frame {
  display: flex;
  width: 946px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-right: 113px;
}

.terminal-text-20 {
  display: flex;
  height: 14px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: #3e417a;
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.terminal-8-frame-2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.terminal-8-frame-3 {
  margin-top: auto;
  bottom: 0;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
}

.terminal-8-frame-4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  align-self: stretch;
}

.terminal-8-frame-5 {
  display: flex;
  width: 19px;
  height: 15px;
  padding: 0px 4px;
  align-items: center;
  gap: 8px;
}

.terminal-text-21 {
  color: #46cdb3;
  font-family: "Almoni DL AAA";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.terminal-8-frame-6 {
  display: flex;
  height: 126px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
}

.terminal-8-frame-7 {
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
}

.terminal-8-frame-8 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  align-self: stretch;
}

.terminal-8-frame-9 {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.terminal-text-22 {
  display: flex;
  width: 74px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  color: #696e83;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.terminal-text-23 {
  display: flex;
  width: 90px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  color: #696e83;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.terminal-8-frame-10 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.terminal-text-24 {
  color: #696e83;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.terminal-text-25 {
  color: #2b308b;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 14px;
}

.terminal-8-frame-11 {
  display: flex;
  height: 27px;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  flex-shrink: 0;
  align-self: stretch;
}

.terminal-8-frame-12 {
  display: flex;
  width: 70px;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(62, 65, 122, 0.11);
}

.terminal-text-26 {
  color: #494949;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.terminal-8-frame-13 {
  display: flex;
  width: 70px;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(174, 193, 255, 0.6);
}

.terminal-8-frame-14 {
  max-height: 270px;
  overflow: auto;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  align-self: stretch;
}

.terminal-8-frame-15 {
  display: flex;
  width: 1005px;
  height: 59px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  margin-right: 80px;
}

.terminal-8-frame-16 {
  display: flex;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 15px;
  background: #d4d7e5;
}

.terminal-8-frame-17 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  flex: 1 0 0;
}

.terminal-text-27 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: #2b308b;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.terminal-8-divider {
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
}

.terminal-9-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.terminal-9-frame-2 {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(240, 241, 245, 0.5);
}

.terminal-9-frame-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  flex: 1 0 0;
}

.terminal-text-28 {
  display: flex;
  height: 15px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #2b3361;
  font-family: "Almoni DL AAA";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.text-edit-2 {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #3e417a;
  font-family: Heebo;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  background: transparent;
}

.text-edit-3 {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  color: #2b3361;
  background: transparent;
}

.terminal-text-29 {
  display: flex;
  width: 90px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.terminal-text-29 {
  display: flex;
  width: 90px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.terminal-text-30 {
  display: flex;
  height: 15px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #5a81fa;
  font-family: "Almoni DL AAA";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.terminal-text-32 {
  display: flex;
  width: 63px;
  height: 47px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #2b308b;
  font-family: "Almoni DL AAA";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
}

.terminal-text-33 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #3e417a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.terminal-text-34 {
  color: #46cdb3;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
