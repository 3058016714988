@font-face {
  font-family: "Almoni DL AAA";
  src: url("./fonts/almoni-dl-aaa-900.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almoni DL AAA";
  src: url("./fonts/almoni-dl-aaa-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almoni DL AAA";
  src: url("./fonts/almoni-dl-aaa-400.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almoni DL AAA";
  src: url("./fonts/almoni-dl-aaa-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almoni DL AAA";
  src: url("./fonts/AlmoniDLAAA-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almoni DL AAA";
  src: url("./fonts/AlmoniDLAAA.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.div-main {
  width: 100vw;
  height: 100vh;
}
