.alert-dialog-overlay-save {
  display: flex;
  width: 445px;
  height: 187px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 15px;
  background: #5a81fa;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.alert-dialog-overlay-2-save {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.alert-dialog-overlay-text-save {
  width: 207px;
  color: #fff;
  text-align: center;
  font-family: "Almoni DL AAA";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
