.div-main-2-3 {
  background-color: #f0f1f5;
  overflow: auto;
  padding: 56px 34px;
}

.model-1-frame {
  display: inline-flex;
  height: 220px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;
}

.model-text-1 {
  display: flex;
  width: 84px;
  height: 47px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #2b308b;
  font-family: "Almoni DL AAA";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
}

.model-2-frame {
  display: flex;
  width: 1714px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.model-2-frame-2 {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.model-2-frame-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}

.model-2-frame-4 {
  display: flex;
  height: 354px;
  padding: 20px 30px 30px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.model-2-frame-5 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
}

.model-2-frame-6 {
  display: flex;
  height: 304px;
  width: 121px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}

.model-2-frame-7 {
  display: flex;
  height: 67px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.model-text-2 {
  width: 83px;
  color: #46cdb3;
  text-align: center;
  font-family: "Almoni DL AAA";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
}

.model-2-frame-9 {
  display: flex;
  width: 80px;
  padding-right: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.model-2-frame-10 {
  display: flex;
  height: 95px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;
}

.model-2-frame-11 {
  display: flex;
  height: 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
}

.model-2-frame-12 {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  background: #e9eaee;
}

.model-2-frame-13 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  flex: 1 0 0;
}

.model-2-frame-14 {
  display: flex;
  width: 135px;
  padding-right: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.model-2-frame-15 {
  display: flex;
  height: 95px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;
}

.model-2-frame-16 {
  display: flex;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}

.model-text-4 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: #1c49d2;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
}

.model-text-5 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: #2b308b;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.model-2-frame-17 {
  display: flex;
  width: 201.4px;
  padding: 0px 9px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  flex: 1 0 0;
  border-right: 1px solid #ececec;
  box-sizing: border-box;
}

.model-2-frame-18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;
}

.model-2-frame-19 {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 28px;
  border-radius: 15px;
  background: #aec1ff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.model-text-6 {
  color: #3e417a;
  text-align: right;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.model-2-frame-27 {
  display: flex;
  width: 281px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.model-2-frame-28 {
  display: flex;
  width: 281px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.model-2-frame-29 {
  display: flex;
  width: 81px;
  height: 47px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  border-radius: 20px;
  border: 1px solid #46cdb3;
  cursor: pointer;
}

.model-2-frame-30 {
  display: flex;
  padding-top: 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
}

.model-2-frame-31 {
  display: flex;
  padding: 10px 30px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.model-2-frame-32 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
}

.model-2-frame-33 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.model-2-frame-34 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.model-text-8 {
  display: flex;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #1c49d2;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.model-2-frame-35 {
  display: flex;
  height: 233px;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
}

.model-text-9 {
  color: #3e417a;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.model-2-frame-36 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  align-self: stretch;
}

.model-2-frame-37 {
  display: flex;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;
}

.model-3-frame {
  display: flex;
  width: 1714px;
  padding-top: 15px;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid #d3d5da;
}

.model-3-frame-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
}

.model-3-frame-3 {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  align-self: stretch;
}

.model-text-10 {
  display: flex;
  width: 154px;
  height: 89px;
  flex-direction: column;
  justify-content: center;
  color: #46cdb3;
  text-align: right;
  font-family: "Almoni DL AAA";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
}

.model-3-frame-4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
}

.model-3-frame-5 {
  display: flex;
  height: 170px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
}

.model-3-frame-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  align-self: stretch;
}

.model-3-frame-7 {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.model-3-frame-8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
}
