.model-text-3 {
  display: flex;
  width: 37.744px;
  height: 13.128px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #2b3361;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
