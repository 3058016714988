.alert-dialog-overlay-edit {
  display: flex;
  width: 445px;
  height: 187px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 15px;
  background: #5a81fa;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.alert-dialog-overlay-2-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.alert-dialog-overlay-3-edit {
  display: flex;
  height: 23px;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  align-self: stretch;
}

.alert-dialog-overlay-text-edit {
  color: #fff;
  text-align: center;
  font-family: "Almoni DL AAA";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  flex: 1 0 0;
}

.alert-dialog-overlay-text-2-edit {
  color: #fff;
  text-align: center;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
}

.alert-dialog-overlay-4-edit {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.alert-dialog-overlay-5-edit {
  display: flex;
  width: 163px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  cursor: pointer;
}

.alert-dialog-overlay-text-3-edit {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-family: "Almoni DL AAA";
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.alert-dialog-overlay-6-edit {
  display: flex;
  width: 163px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  border-radius: 15px;
  border: 1px solid #fff;
  box-sizing: border-box;
  cursor: pointer;
}
