.terminal-4-frame-14 {
  display: flex;
  height: 44px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
}

.terminal-4-frame-15 {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  background: #e9eaee;
}

.terminal-4-frame-16 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  flex: 1 0 0;
}

.terminal-text-30 {
  display: flex;
  height: 15px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #5a81fa;
  font-family: "Almoni DL AAA";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
