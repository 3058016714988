.div-main-2 {
  background-color: #f0f1f5;
  overflow: auto;
  padding: 56px 34px;
}

.model-text {
  width: 133px;
  color: #2b308b;
  font-family: "Almoni DL AAA";
  font-size: 29px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
}

.search-terminal-text {
  flex: 1 0 0;
  color: #2b308b;
  font-family: "Almoni DL AAA";
  font-size: 24px;
  font-weight: 900;
  height: 26px;
  line-height: normal;
}

.search-frame {
  border-radius: 10px;
  background: #2b308b;
  height: 44px;
  width: 44px;
  padding: 8px;
  gap: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.filter-frame-1 {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 46px;
}

.filter-frame-2 {
  display: flex;
  width: 556px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
}

.filter-frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  align-self: stretch;
}

.filter-frame-4 {
  display: flex;
  height: 60px;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}
