.model-text-shared-1 {
  display: flex;
  width: 63px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #2b308b;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
}

.text-edit {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  color: #2b308b;
  text-align: right;
  background: transparent;
}

.terminal-5-frame-11 {
  display: flex;
  height: 47px;
  padding: 15px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 20px;
  background: #46cdb3;
  box-sizing: border-box;
  cursor: pointer;
}

.terminal-text-31 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: center;
  color: #fff;
  text-align: center;
  font-family: "Almoni DL AAA";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.alert-dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.model-frame {
  border-radius: 0px 10px 10px 0px;
  background-color: #595d70;
  left: 0;
  position: absolute;
  cursor: pointer;
}
