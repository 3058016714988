.model-2-frame-11 {
  display: flex;
  height: 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
}

.model-2-frame-12 {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  background: #e9eaee;
}

.model-2-frame-13 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  flex: 1 0 0;
}

.overlay-text-1 {
  position: absolute;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  color: #2b308b;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
